import { api, CustomError } from "../../utils/api";
import { SET_LIST_DEMANDES, SET_LIST_DEPARTEMENTS } from "../mutations_types";
export const deptBaseUrl = "/departements";

export const mutations = {
  [SET_LIST_DEPARTEMENTS]: (state, data) => {
    state.globals.departementsListe = data;
  },
};
export const actions = {
  setDepartementsListe({ commit }) {
    return api
      .get(deptBaseUrl)
      .then(res => commit(SET_LIST_DEPARTEMENTS, res.data))
      .catch(e => {
        console.log(e.response);
        let infos = e.response.data;
        throw new CustomError({ ...infos });
      });
  },
  setDepartementDemandesCommunes({ commit }, data) {
    if (data.codes?.length === 0) return commit(SET_LIST_DEMANDES, []);
    return api
      .get(`${deptBaseUrl}/${data.codes}/demandes?includecontrats=${data.includecontrats}`)
      .then(res => commit(SET_LIST_DEMANDES, res.data))
      .catch(e => {
        console.log(e.response);
        let infos = e.response.data;
        throw new CustomError({ ...infos });
      });
  },
};
export const getters = {
  departemntsListe: state => {
    return state.globals.departementsListe;
  },
};
