import "moment/locale/fr";
import { SET_APP_FOOTER, SET_APP_HEADER, SET_ERROR, SET_LOADING } from "../mutations_types";

// PAS DE PROMISE DANS LES MUTATIONS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// tous les changements du store doivent être fait ici
export const mutations = {
  [SET_LOADING]: (state, data) => {
    state.globals.loading = data;
  },
  [SET_APP_FOOTER]: (state, data) => {
    state.globals.showAppFooter = data;
  },
  [SET_APP_HEADER]: (state, data) => {
    state.globals.showAppHeader = data;
  },
  [SET_ERROR]: (state, data) => {
    state.globals.hasError = Object.keys(data).length > 0;
    state.globals.error = data;
  },
};
export const actions = {
  setAppFooter({ commit }, data) {
    return commit(SET_APP_FOOTER, data);
  },
  setAppHeader({ commit }, data) {
    return commit(SET_APP_HEADER, data);
  },
  setLoading({ commit }, data) {
    return commit(SET_LOADING, data);
  },
  setError({ commit }, data) {
    return commit(SET_ERROR, data);
  },
};
export const getters = {
  showAppHeader: state => {
    return state.globals.showAppHeader;
  },
  showAppFooter: state => {
    return state.globals.showAppFooter;
  },
  loading: state => {
    return state.globals.loading;
  },
  hasError: state => {
    return state.globals.hasError;
  },
  error: state => {
    return state.globals.error;
  },
};
