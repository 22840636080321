<template>
  <div class="chart-wrapper">
    <h3 :class="customTitleClass">{{ title }}</h3>
    <div>
      <component
        :is="chartType"
        :chart-id="id"
        :chart-options="options"
        :chart-data="data"
        :css-classes="cssClass"
        :width="width"
        :height="height"
      />
    </div>
  </div>
</template>

<script>
import { Bar, Doughnut, Pie, Radar, Scatter, Line, PolarArea } from "vue-chartjs";
import {
  Chart as ChartJS,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  Filler,
  LineElement,
} from "chart.js";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  RadialLinearScale,
  PointElement,
  Filler,
  LineElement,
  PointElement,
  RadialLinearScale
);

export default {
  name: "DataChart",
  components: { Bar, Doughnut, Pie, Radar, Scatter, Line, PolarArea },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {
        return {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
              labels: {
                usePointStyle: true,
                textAlign: "left",
              },
            },
          },
        };
      },
    },
    chartType: {
      type: String,
      validator(value) {
        return ["Bar", "Doughnut", "Pie", "Radar", "Scatter", "Line", "PolarArea"].includes(value);
      },
    },
    title: {
      type: String,
      default: "Grraphique des données",
    },
    cssClass: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "data-chart",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    customTitleClass: {
      type: String,
      default: "",
    },
  },
};
</script>
