export function normalizePlurielWord(word, n) {
  return Math.abs(n) > 1 ? `${word}s` : word;
}

export function downloadCSV({ obj = [], output = "export.csv", fields = [] } = {}) {
  let csv = toCSV(obj, fields);
  let file = new File(csv, "");
  let divApp = document.getElementById("root");
  let a = document.createElement("a");
  divApp.append(a);
  a.setAttribute("href", URL.createObjectURL(file));
  a.setAttribute("download", output);
  a.click();
  a.remove();
}
function toCSV(obj, fields) {
  if (fields?.length === 0) return ["no fields have been selected"];
  let headers = fields.join(";") + "\n";
  let values = obj
    .map(o => fields.map(f => f.split(".").reduce((a, b) => a[b], o)).join(";"))
    .join("\n");
  return Array.from([headers, values]);
}
