/*****************************************************************************
 * Properties
 *****************************************************************************/

/*
 * ACCOUNTS_ROLES
 */
export const ACCOUNTS_ROLES = Object.freeze({
  "@ADMIN": "@ADMIN",
  "@DA": "@DA",
});

export const WORKFLOW_STEPS = Object.freeze({
  NOUVEAU: {
    libelle: "Nouveau",
    ordre: 1,
    icon: "fa-light fa-check",
    date_prop: "date_creation",
  },
  DOSSIER: {
    libelle: "Dossier",
    ordre: 2,
    icon: "fa-light fa-file-image",
    date_prop: "date_dossier",
  },
  PRESENTATION: {
    ordre: 3,
    libelle: "Présentation",
    step: "Présentation",
    icon: "fa-light fa-display",
    date_prop: "date_presentation",
  },
  CONVENTION: {
    libelle: "Convention",
    ordre: 4,
    icon: "fa-light fa-pen-to-square",
    date_prop: "date_convention",
  },
  INACTIF: {
    libelle: "Inactif",
    ordre: 5,
    icon: "fa-light fa-ban",
    date_prop: "date_abandon",
  },
});
