<template>
  <div :class="['field', validated && errors ? 'field-error' : '']">
    <div class="field-block">
      <div v-if="validated && errors" class="error-block">
        <span class="error-message">{{ errors }}</span>
      </div>
      <input
        class="form-control"
        :type="type"
        :value="modelValue"
        :min="min"
        :disabled="disabled"
        :placeholder="placeholder"
        @change="onChange"
        @input="onChange"
        @blur="validated = true"
        :id="id"
        autocomplete="off"
      />
    </div>
    <label class="form-label" v-if="label" :for="id"> {{ label }}</label>
  </div>
</template>

<script>
import { getError } from "../../utils/validate";
export default {
  emits: ["update:modelValue"],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    min: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Number, Date, Boolean],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    rules: {
      type: Object,
      default: {},
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      validated: false,
    };
  },
  computed: {
    errors() {
      return getError(this.modelValue, { ...this.rules }, {});
    },
  },

  methods: {
    onChange(e) {
      return this.type === "checkbox"
        ? this.$emit("update:modelValue", e.target.checked)
        : this.$emit("update:modelValue", e.target.value);
    },
    validate() {
      this.validated = true;
    },
  },
};
</script>
<style scoped></style>
