import Admin from "./IndexAdmin.vue";
import { routes as accueilRoutes } from "./accueil";
import { routes as userRoutes } from "./users";
import { routes as compteRoutes } from "./compte";
import { ACCOUNTS_ROLES } from "../../../../mutuelle-communale-api/app/configs/Properties";

export const routes = [
  {
    path: "/admin",
    name: "Admin.Principale",
    component: Admin,
    redirect: "/admin/accueil",
    children: [...accueilRoutes, ...userRoutes, ...compteRoutes],
    meta: {
      authRequired: true,
      roles: [ACCOUNTS_ROLES["@ADMIN"]],
    },
  },
];
