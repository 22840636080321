<template>
  <div :class="[, validated && errors ? 'field-error' : '']">
    <div class="field-date-interval">
      <div v-if="validated && errors" class="error-block">
        <span class="error-message">{{ errors }}</span>
      </div>
      <div class="input-date start">
        <label>Du</label>
        <input
          class="field date"
          :type="type"
          :value="modelValue.startDate"
          :placeholder="placeholder"
          @change="e => onChange('startDate', e.target.value)"
          @input="e => onChange('startDate', e.target.value)"
          @blur="validated = true"
          autocomplete="off"
        />
      </div>
      <i class="fa fa-arrow-right ms-2 me-2" aria-hidden="true"></i>
      <div class="input-date end">
        <label>Au</label>
        <input
          class="field date"
          :type="type"
          :value="modelValue.endDate"
          :placeholder="placeholder"
          :min="modelValue.startDate"
          @change="e => onChange('endDate', e.target.value)"
          @input="e => onChange('endDate', e.target.value)"
          autocomplete="off"
          ref="endDate"
          name="end"
        />
      </div>
    </div>

    <label class="form-label" v-if="label"> {{ label }}</label>
  </div>
</template>

<script>
import { getError } from "../../utils/validate";
export default {
  emits: ["update:modelValue"],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "date",
    },
    min: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Object,
      default: { startDate: null, endDate: null },
    },
    placeholder: {
      type: String,
      default: null,
    },
    rules: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      validated: false,
    };
  },
  computed: {
    errors() {
      return getError(this.modelValue, { ...this.rules }, {});
    },
  },

  methods: {
    onChange(key, value) {
      return this.$emit("update:modelValue", { ...this.modelValue, [key]: value });
    },
    validate() {
      this.validated = true;
    },
  },
};
</script>
<style scoped>
.field-date-interval {
  display: flex;
  align-items: center;
  justify-content: stretch;
  margin: 0;
}
.field-date-interval .date {
  margin: 0;
  margin-right: 10px;
}
.input-container input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}
</style>
