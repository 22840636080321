import * as VueRouter from "vue-router";
import store from "../store";

// import { routes as homeRoutes } from "./home/index";
import { routes as authRoutes } from "./authentification/index";
import { routes as adminRoutes } from "./admin/index";
import { routes as departementsRoutes } from "./departements/index";

import NotFound from "../components/layouts/NotFound.vue";

const routes = [
  // uncomment si nous avons pas un route home ou principale et faire un redirecte
  {
    path: "/",
    name: "Principale",
    redirect: "/departements",
  },
  // ...homeRoutes,
  ...adminRoutes,
  ...authRoutes,
  ...departementsRoutes,
  {
    path: "/:pathMatch(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    (to.meta?.authRequired && !store.getters.isUserLogged) ||
    (to.meta?.roles && !to.meta?.roles?.includes(store.getters.userRole))
  ) {
    next({ name: "Auth.Login" });
  } else {
    next();
  }
});

export default router;
