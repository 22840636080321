<template>
  <tr>
    <td>
      <span>{{ user.nom }}</span>
    </td>
    <td>
      <span>{{ user.prenom }}</span>
    </td>
    <td>
      <span>{{ user.email }}</span>
    </td>
    <td>
      <span>{{ user.role }}</span>
    </td>
    <td>
      <div class="btn-group">
        <button class="btn tooltip-btn" @click="$emit('resetPass', user.email)">
          <i class="fal fa-envelope"></i>
          <span class="tooltip-text">Renvoyer l’email de création&nbsp;de&nbsp;compte</span>
        </button>
        <button class="btn tooltip-btn" @click="$emit('editUser', user.id_user)">
          <i class="fal fa-pencil"></i>
          <span class="tooltip-text">Editer</span>
        </button>
        <button class="btn tooltip-btn" @click="$emit('deleteUser', user.id_user)">
          <i class="fal fa-trash-alt"></i>
          <span class="tooltip-text">Supprimer</span>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  emits: ["resetPass", "editUser", "deleteUser"],
  name: "UserDetails",
  props: {
    user: {
      type: Object,
      default: {},
    },
  },
};
</script>

<style></style>
