import AccueilPrincipale from "./IndexAccueil";
import AdminAccueil from "./AdminAccueil";
import { ACCOUNTS_ROLES } from "../../../../../mutuelle-communale-api/app/configs/Properties";

export const routes = [
  {
    path: "accueil",
    name: "Admin.Accueil.Principale",
    component: AccueilPrincipale,
    children: [
      {
        path: "",
        name: "Admin.Accueil",
        component: AdminAccueil,
        meta: {
          authRequired: true,
          roles: [ACCOUNTS_ROLES["@ADMIN"]],
        },
      },
    ],
  },
];
