import { api } from "../../utils/api";

import "moment/locale/fr";
import { ACCOUNTS_ROLES } from "../../../../mutuelle-communale-api/app/configs/Properties";

// tous les types des mutations sont définis ici
export const SET_USER_LOGGED = "SET_USER_LOGGED";
export const SET_USER = "SET_USER";
export const SET_LOADING = "SET_LOADING";
export const SET_APP_FOOTER = "SET_APP_FOOTER";
export const SET_USERS_LISTE = "SET_USERS_LISTE";
export const ADD_USER_TO_LISTE = "ADD_USER_TO_LISTE";
export const REMOVE_USER_FROM_LISTE = "REMOVE_USER_FROM_LISTE";

// axios.defaults.withCredentials = true;
// toutes les mutations sont appelées ici
// PAS DE PROMISE DANS LES MUTATIONS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// tous les changements du store doivent être fait ici
export const mutations = {
  [SET_USER]: (state, user) => {
    state.globals.user = user;
  },
  [SET_USER_LOGGED]: (state, data) => {
    state.globals.isUserLogged = data;
  },
  [SET_USERS_LISTE]: (state, data) => {
    state.globals.usersListe = data;
  },
  [ADD_USER_TO_LISTE]: (state, data) => {
    state.globals.usersListe = state.globals.usersListe.filter(
      user => user.id_user !== data.id_user
    );
    state.globals.usersListe.push(data);
  },
  [REMOVE_USER_FROM_LISTE]: (state, data) => {
    state.globals.usersListe = state.globals.usersListe.filter(
      user => user.id_user !== data.id_user
    );
  },
};

export const actions = {
  checkLogin({ commit }, data) {
    return api
      .post("auth/login", { ...data })
      .then(res => {
        if (res && res.data && res.data.user) {
          commit(SET_USER, res.data.user);
          commit(SET_USER_LOGGED, true);
          return true;
        } else {
          throw String("Erreur login");
        }
      })
      .catch(e => {
        commit(SET_USER, null);
        commit(SET_USER_LOGGED, false);
        return false;
      });
  },
  getUserById({ commit }, id) {
    return api
      .get(`users/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch(e => {
        console.log(e);
        return false;
      });
  },
  getUserByToken({ commit }, token) {
    return api
      .get(`auth/token/${token}`)
      .then(({ data }) => {
        commit(SET_USER, data.user);
        return data.user;
      })
      .catch(e => {
        console.log(e);
        return false;
      });
  },
  isUserExist({ commit }, data) {
    return api
      .post("auth/user/exist", { email: data })
      .then(({ data }) => {
        return data.isExist;
      })
      .catch(e => {
        return false;
      });
  },
  setUser({ commit }, data) {
    return commit(SET_USER, data);
  },
  setUserLogged({ commit }, data) {
    return commit(SET_USER_LOGGED, data);
  },
  logOff({ commit }) {
    return api
      .post("auth/logout")
      .then(() => {
        commit(SET_USER_LOGGED, false);
        return commit(SET_USER, null);
      })
      .catch(e => {
        console.log(e);
        return false;
      });
  },
  sendInitPassword({ commit }, data) {
    return api
      .post("auth/init-password", { email: data })
      .then(res => true)
      .catch(e => {
        console.log(e);
        return false;
      });
  },
  resetPassword({ commit }, data) {
    return api
      .post("auth/reset-password", { email: data })
      .then(res => true)
      .catch(e => {
        console.log(e);
        return false;
      });
  },
  updateUserPassword({ commit, dispatch }, data) {
    return dispatch("isUserExist", data.email)
      .then(isExist => {
        if (!isExist) {
          throw String("Utilisateur n'existe pas");
        }
        return isExist;
      })
      .then(() => {
        return api.post("auth/new-password", { ...data }).then(res => true);
      })
      .then(isUpdate => {
        if (!isUpdate) {
          throw String("Une erreur s'est produite lors de la mise à jour du mot de pass");
        }
        return isUpdate;
      });
  },
  deleteUserDB({ commit }, data) {
    return api
      .delete(`/users/${data.id}`)
      .then(res => {
        commit(REMOVE_USER_FROM_LISTE, { id_user: data.id });
        return true;
      })
      .catch(e => {
        console.log(e);
        return false;
      });
  },
  setUsersListe({ commit }, data) {
    return api
      .get("/users")
      .then(res => {
        return commit(SET_USERS_LISTE, res.data.users);
      })
      .catch(e => {
        console.log(e);
        return commit(SET_USERS_LISTE, []);
      });
  },
  addUserDB({ commit }, data) {
    return api({
      method: data.id_user ? "PUT" : "POST",
      url: data.id_user ? `/users/${data.id_user}` : "/users",
      data: { ...data },
    })
      .then(res => {
        let user = res.data.user;
        commit(ADD_USER_TO_LISTE, user);
        return true;
      })
      .catch(e => {
        console.log(e);
        throw e;
      });
  },
  getLoggedUser({ commit }) {
    return api
      .get("auth/loggeduser")
      .then(res => {
        if (res.status === 200) {
          commit(SET_USER, res.data.user);
          commit(SET_USER_LOGGED, true);
          return true;
        }
        throw String("erreur");
      })
      .catch(e => {
        commit(SET_USER, null);
        commit(SET_USER_LOGGED, false);
        return false;
      });
  },
};

export const getters = {
  usersListe: state => {
    return state.globals.usersListe.sort((a, b) => a.id_user - b.id_user);
  },
  currentUser: (state, getters) => {
    return getters.isUserLogged ? state.globals.user : null;
  },
  isUserLogged: state => {
    return state.globals.isUserLogged;
  },
  userRole: (state, getters) => {
    return getters.isUserLogged ? state.globals.user?.role : null;
  },
  isAdmin: (state, getters) => {
    return getters.currentUser?.role === ACCOUNTS_ROLES["@ADMIN"];
  },
  isDA: (state, getters) => {
    return getters.currentUser?.role === ACCOUNTS_ROLES["@DA"];
  },
  getUserFromListe: (state, getters) => id => {
    return getters.usersListe.find(u => u.id_user === id);
  },
};
