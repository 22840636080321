<template>
  <div class="login">
    <div class="login-overflow">
        <router-view />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
