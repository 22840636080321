import Vuex from "vuex";
import { mutations, actions, getters } from "./modules";
import state from "./state";

const store = new Vuex.Store({
  // plugins: [vuexPersistState],
  state,
  mutations,
  actions,
  getters,
  modules: {},
  // strict: !__PROD__,
});

export default store;
