// users
import { ACCOUNTS_ROLES } from "../../../../../mutuelle-communale-api/app/configs/Properties";
import AdminUsers from "./IndexUsers";
import AdminListeUsers from "./ListeUsers.vue";

export const routes = [
  {
    path: "users",
    name: "Admin.Users",
    component: AdminUsers,
    redirect: "/admin/users/listeusers",
    children: [
      {
        path: "listeusers",
        name: "Admin.ListeUsers",
        component: AdminListeUsers,
      },
    ],
    meta: {
      authRequired: true,
      roles: [ACCOUNTS_ROLES["@ADMIN"]],
    },
  },
];
