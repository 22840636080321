<template>
  <main>
    <header>
      <span class="last-maj">Dernière mise à jour le {{ lastMAJ }}</span>
      <router-link class="logo" :to="{ name: 'Departements' }">
        <i class="fa-light fa-sitemap"></i>
        <span>Mutuelles Communales</span>
      </router-link>
      <nav>
        <router-link class="nav-link" :to="{ name: 'Departements.Stats' }">
          <i class="fa-solid fa-signal-bars"></i>
          <span>Statistiques</span>
        </router-link>
        <button class="nav-link" v-if="isUserLogged" @click="to_update = true">
          <i class="fa-solid fa-arrow-up-from-bracket"></i>
          <span>MAJ</span>
        </button>
        <router-link class="nav-link" v-if="isAdmin" :to="{ name: 'Admin.ListeUsers' }">
          <i class="fa-solid fa-user-tie"></i>
          <span>Utilisateurs</span>
        </router-link>

        <button class="nav-link" @click="onLog">
          <i
            :class="{
              'fa-regular': true,
              'fa-user-lock': !isUserLogged,
              'fa-user-check': isUserLogged,
            }"
          ></i>
          <span v-if="isUserLogged"> Se déconnecter </span>
          <span v-else> Se connecter </span>
        </button>
      </nav>
    </header>
    <div class="container-fluid">
      <router-view />
      <update-data v-if="to_update" data_type="demandes" />
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UpdateData from "./UpdateData.vue";
export default {
  components: { UpdateData },
  name: "IndexDepartement",
  data() {
    return {
      to_update: false,
      lastMAJ: null,
    };
  },
  computed: {
    ...mapGetters(["isUserLogged", "isAdmin"]),
    // lastMAJ() {
    //   return this.$moment().format("DD/MM/YYYY");
    // },
  },
  methods: {
    ...mapActions(["logOff", "setLoading", "getLastMaj"]),
    onLog() {
      if (this.isUserLogged) return this.logOff();
      return this.$router.push({ name: "Auth.Login" });
    },
    setDateMaj(route) {
      let type = route === "Departements.Stats" ? "contrats" : "demandes";
      return this.setLoading(true)
        .then(() => this.getLastMaj(type))
        .then(r => {
          this.lastMAJ = this.$moment(r.date_upload).format("DD/MM/YYYY à HH[h]mm");
        })
        .then(() => this.setLoading(false));
    },
  },
  mounted() {
    this.setDateMaj(this.$route.name);
  },
  watch: {
    $route: {
      handler(val) {
        return this.setDateMaj(val.name);
      },
    },
  },
};
</script>
