<template>
  <!-- Modale MAJ -->
  <custom-modal @confirm="update_data" @close="$parent.to_update = false">
    <template v-slot:modalTitle>Mettre à jour les données</template>
    <template v-slot:modalBody>
      <!-- <div>
        <field-radio-buttons
          :buttons="options_maj"
          v-model="model.type_maj"
          :rules="validations.type_maj"
          ref="model.type_maj"
        />
      </div> -->
      <!-- <div v-if="model.type_maj"> -->
      <div v-if="model.type_maj">
        <span>Charger le fichier pour la mise à jour des {{ model.type_maj }}</span>
        <field-dropzone-file
          class="inline"
          v-model="model.file"
          :rules="validations.file"
          ref="model.file"
          :acceptFilesType="[
            'text/csv',
            '.csv',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
          ]"
        />
      </div>
    </template>
    <template v-slot:closeBtn>Annuler</template>
    <template v-slot:confirmationBtn>Suivant <i class="fa-solid fa-arrow-right-long"></i></template>
  </custom-modal>
</template>

<script>
import { mapActions } from "vuex";
import CustomModal from "../../components/customize/CustomModal.vue";
import FieldDropzoneFile from "../../components/customize/FieldDropzoneFile.vue";
import FieldRadioButtons from "../../components/customize/FieldRadioButtons.vue";
import { checkValidationRefs } from "../../utils/validate";
export default {
  components: { CustomModal, FieldRadioButtons, FieldDropzoneFile },
  name: "UpdateCommune",
  emits: ["updateDone"],
  props: {
    data_type: {
      type: String,
      default: "demandes",
    },
  },
  data() {
    return {
      options_maj: [
        { value: "stats", label: "Les statistiques" },
        { value: "communes", label: "Les communes" },
      ],
      model: {
        type_maj: this.data_type,
        file: null,
      },
      validations: {
        type_maj: { required: "Merci de choisir une option pour la mise à jour" },
        file: { required: "Merci de selectionner un fichier" },
      },
    };
  },
  computed: {},
  methods: {
    ...mapActions([
      "setLoading",
      "setError",
      "uploadDemandesCommunes",
      "setDepartementDemandesCommunes",
    ]),
    update_data() {
      return this.setLoading(true)
        .then(() => checkValidationRefs(this.$refs))
        .then(res => {
          if (!res) return window.scroll(0, 0);
          return this.uploadDemandesCommunes(this.model);
        })
        .then(() =>
          this.setDepartementDemandesCommunes({
            codes: "all",
            includecontrats: this.model.type_maj === "contrats",
          })
        )
        .then(() => {
          this.$parent.to_update = false;
          return this.$emit("updateDone");
        })
        .catch(e => this.setError(e))
        .then(() => this.setLoading(false));
    },
  },
};
</script>

<style></style>
