import {
  actions as actionsUsers,
  mutations as mutationsUsers,
  getters as gettersUsers,
} from "./users";
import {
  actions as actionsDepart,
  mutations as mutationsDepart,
  getters as gettersDepart,
} from "./departements";
import {
  actions as actionsCommune,
  mutations as mutationsCommune,
  getters as gettersCommune,
} from "./communes";
import {
  actions as actionsDemande,
  mutations as mutationsDemande,
  getters as gettersDemande,
} from "./demandes";

import {
  actions as actionsGlobal,
  mutations as mutationsGlobal,
  getters as gettersGlobal,
} from "./globals";

export const actions = {
  ...actionsGlobal,
  ...actionsUsers,
  ...actionsDepart,
  ...actionsCommune,
  ...actionsDemande,
};
export const mutations = {
  ...mutationsGlobal,
  ...mutationsUsers,
  ...mutationsDepart,
  ...mutationsCommune,
  ...mutationsDemande,
};
export const getters = {
  ...gettersUsers,
  ...gettersGlobal,
  ...gettersDepart,
  ...gettersCommune,
  ...gettersDemande,
};
