<template>
  <custom-modal @close="onClose" :hasConfirmBtn="false">
    <template v-slot:modalTitle>
      <p v-if="error.code">{{ error.code }}</p>
    </template>
    <template v-slot:modalBody>
      <p v-if="error.message">{{ error.message }}</p>
      <p v-if="error.description">{{ error.description }}</p>
    </template>
  </custom-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomModal from "../customize/CustomModal.vue";
export default {
  components: { CustomModal },
  name: "ErrorApi",
  computed: {
    ...mapGetters(["error"]),
  },
  methods: {
    ...mapActions(["setError"]),
    onClose() {
      return this.setError({});
    },
  },
};
</script>

<style></style>
