import axios from "axios";

export const api = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  withCredentials: true,
});

export class CustomError {
  constructor({ code = null, message = null, description = null, status = null } = {}) {
    this.code = code;
    this.message = message;
    this.description = description;
    this.status = status;
  }
}
