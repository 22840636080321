import { CustomError } from "../utils/api";

const state = {
  globals: {
    user: {},
    usersListe: [],
    departementsListe: [],
    communesListe: [],
    demandesListe: [],
    loading: false,
    showAppHeader: false,
    showAppFooter: false,
    isUserLogged: false,
    hasError: false,
    error: new CustomError(),
  },
};

export default state;
