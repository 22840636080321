<template>
  <div>
    <h1>Bonjour {{ userName }}</h1>
    <h2>Vous êtes connecté en tant que {{ userRole }}</h2>
  </div>
</template>

<script>
import { mixinAction } from "../utils";
export default {
  name: "AdminAccueil",
  data() {
    return {};
  },
  mixins: [mixinAction],
  computed: {
    userName() {
      return `${this.currentUser?.nom} ${this.currentUser?.prenom}`;
    },
  },
};
</script>
