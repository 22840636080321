import Index from "./IndexDepartements";
import DemandesStats from "./DemandesStats";
import ListeDepartements from "./ListeDepartements";
import DemandeWorkflow from "./commune/DemandeWorkflow";

export const routes = [
  {
    path: "/departements",
    name: "Departements",
    component: Index,
    redirect: { name: "Departements.Liste" },
    children: [
      {
        path: "liste",
        name: "Departements.Liste",
        component: ListeDepartements,
      },
      {
        path: "demande/:id/workflow",
        name: "Departements.Commune.Workflow",
        component: DemandeWorkflow,
      },
      {
        path: "stats",
        name: "Departements.Stats",
        component: DemandesStats,
      },
    ],
  },
];
