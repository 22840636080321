<template>
  <div class="navbar">
    <nav>
      <div class="user-profil">{{ userName }}</div>
      <router-link :to="{ name: 'Principale' }">Mutuelle Communale</router-link>
      <router-link :to="{ name: 'Admin.Accueil.Principale' }">
        <i class="menu-icon-accueil"></i>
        <span>Accueil</span>
      </router-link>
      <router-link :to="{ name: 'Admin.ListeUsers' }">
        <i class="menu-icon-utilisateurs"></i>
        <span>Utilisateurs</span>
      </router-link>
    </nav>
    <img
      src="@/assets/images/logo-groupama-loire-bretagne.svg"
      id="logo"
      alt="Logo Groupama Loire Bretagne"
    />
  </div>
  <main>
    <div class="main-header">
      <div class="main-header-left"></div>
      <div class="main-header-right">
        <button class="btn btn-primary btn-small" v-if="isUserLogged" @click="onLogOff">
          Se déconnecter
        </button>
      </div>
    </div>
    <div class="content">
      <router-view />
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["isUserLogged", "currentUser", "isAdmin"]),
    userName() {
      return `${this.currentUser?.nom} ${this.currentUser?.prenom}`;
    },
  },
  methods: {
    ...mapActions(["logOff", "setAppFooter", "setAppHeader", "setUserLogged"]),
    onLogOff() {
      return this.logOff().then(() => this.$router.push({ name: "Auth.Login" }));
    },
  },
  created() {
    return this.setAppHeader(false).then(() => this.setAppFooter(false));
  },
};
</script>

<style></style>
