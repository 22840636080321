<template>
  <div>
    <h1 class="text-center">Prochainement disponible...</h1>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "AdminCompte",
};
</script>

<style></style>
