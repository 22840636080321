<template>
  <loading v-if="loading" />
  <div v-if="!global.newPassUpdated" class="box animated">
    <div class="box-header">
      <img
        src="@/assets/images/logo-groupama-loire-bretagne.svg"
        class="logo-login"
        alt="Logo Groupama Loire Bretagne"
      />
      <div v-if="user" class="login-message">Saisissez un nouveau de mot de passe</div>
      <div v-else class="login-message">Le lien n'est pas valide</div>
    </div>
    <div v-if="user" class="box-content">
      <form @submit.prevent="submit">
        <div class="row">
          <custom-input-type
            class="col-12"
            label="Mot de passe *"
            type="password"
            v-model="user.newPass"
            :rules="validations.newPass"
            ref="user.newPass"
          />
          <custom-input-type
            class="col-12"
            label="Confirmer le mot de passe *"
            type="password"
            v-model="user.confirmedPass"
            :rules="validations.confirmedPass"
            ref="user.confirmedPass"
          />
          <div class="col-12 d-grid">
            <button class="btn btn-login btn-secondary">Modifier</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <custom-modal v-if="global.errorMsg" @close="global.errorMsg = null" :hasFooter="false">
    <template v-slot:modalBody>
      <span>Erreur </span><br />
      <span>{{ global.errorMsg }}</span>
    </template>
  </custom-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomInputType from "../../components/customize/CustomInputType.vue";
import Loading from "../../components/layouts/Loading.vue";
import { checkValidationRefs } from "../../utils/validate";
import CustomModal from "../../components/customize/CustomModal.vue";

export default {
  components: { Loading, CustomInputType, CustomModal },
  props: {},
  data() {
    return {
      user: {},
      formValid: true,
      global: {
        errorMsg: null,
        newPassUpdated: false,
      },
      apiError: null,
    };
  },
  computed: {
    ...mapGetters(["loading", "currentUser"]),

    validations() {
      return {
        newPass: {
          required: "Merci de définir un nouveau de mot de passe",
        },
        confirmedPass: {
          required: "Merci de définir un nouveau de mot de passe",
          equalsModel: {
            model: this.user.newPass,
            message: "Les deux mots de passe doivent être identiques",
          },
        },
      };
    },
  },
  methods: {
    ...mapActions(["setLoading", "getUserByToken", "isUserExist", "updateUserPassword"]),
    submit() {
      this.checkValidations();
      if (!this.formValid) {
        return window.scroll(0, 0);
      }
      return this.setLoading(true)
        .then(res => {
          return this.updateUserPassword(this.user);
        })
        .then(isUpdate => {
          if (isUpdate) {
            this.global.newPassUpdated = true;
            return this.$router.push({ name: "Auth.Login" });
          }
        })
        .catch(e => {
          this.global.errorMsg = e;
        })
        .then(() => this.setLoading(false));
    },
    checkValidations() {
      this.formValid = checkValidationRefs(this.$refs);
    },
    getError() {
      return this.user !== "error";
    },
  },
  watch: {
    $route: {
      handler(val) {
        this.user = this.currentUser;
      },
    },
  },
  mounted() {
    // on fait un call a l'api pour recuperer les informations
    return this.setLoading(true)
      .then(() => this.getUserByToken(this.$route.params.reset_token))
      .then(res => {
        this.user = res;
      })
      .then(() => this.setLoading(false))
      .catch(e => {
        this.user = "error";
      });
  },
};
</script>
