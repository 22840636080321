<template>
  <div v-if="departemntsListe.length > 0">
    <div class="table-header">
      <div class="table-header-main">
        <custom-multi-check-box
          class="choix-departements"
          :buttons="departementsBtns"
          v-model="current_codes"
        />
        <!-- <custom-input-type type="radio" label="actif" v-model="is_actif" /> -->
        <div class="custom-radio-wrapper">
          <input type="radio" v-model="is_actif" id="actif" :value="true" />
          <label for="actif">ACTIF</label>
          <input type="radio" v-model="is_actif" id="inactif" :value="false" />
          <label for="inactif">INACTIF</label>
        </div>
      </div>
      <div>
        <button class="btn btn-primary" @click="new_demande = true">
          <i class="fa-light fa-circle-plus"></i><span>Nouvelle demande</span>
        </button>
      </div>
    </div>
    <div>
      <liste-demandes :codes_departements="current_codes" :actif="is_actif" />
    </div>
    <add-demande v-if="new_demande" />
  </div>
  <div v-else class="no-data">Aucun departement enregistré</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddDemande from "./commune/AddDemande.vue";
import CustomInputType from "../../components/customize/CustomInputType.vue";
import ListeDemandes from "./commune/ListeDemandes.vue";
import FieldRadioButtons from "../../components/customize/FieldRadioButtons.vue";
import CustomMultiCheckBox from "../../components/customize/CustomMultiCheckBox.vue";
export default {
  components: {
    ListeDemandes,
    AddDemande,
    CustomInputType,
    FieldRadioButtons,
    CustomMultiCheckBox,
  },
  name: "listeDepartements",
  data() {
    return {
      is_actif: true,
      new_demande: false,
      current_codes: [],
    };
  },
  computed: {
    ...mapGetters(["departemntsListe", "isUserLogged"]),
    departementsBtns() {
      let btns = [];
      this.departemntsListe.map(d =>
        btns.push({ label: d.code_departement, value: d.code_departement })
      );
      return btns;
    },
  },
  methods: {
    ...mapActions(["setDepartementsListe", "setLoading", "setError"]),
  },
  mounted() {
    return this.setLoading(true)
      .then(() => this.setDepartementsListe())
      .catch(e => this.setError(e))
      .then(() => this.setLoading(false));
  },
};
</script>
