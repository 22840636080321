<template>
  <div
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    style="display: block"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <slot name="headerTitle">
            <h5 class="modal-title">Traitement en cours</h5>
          </slot>
        </div>
        <div class="modal-body">
          <slot name="loadingMessage"> </slot>
          <div class="spinner-grow text-primary"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
