<template>
  <div class="container">
    <div class="page-header">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-2">
            <router-link :to="{ name: 'Departements' }">
              <span><i class="fa-solid fa-arrow-left-long"></i> Retour </span></router-link
            >
          </div>
          <div class="col-auto">
            <h1 v-if="demande && demande.nom_commune">{{ demande.nom_commune }}</h1>
          </div>
          <div class="col-2"></div>
        </div>
      </div>
    </div>
    <div v-if="demande" class="page-content">
      <div class="container">
        <div class="row">
          <div class="col col-xs-6 col-sm-12 col-md-6">
            <!-- Steps Line -->
            <div class="step-line">
              <h2>Suivi</h2>
              <div class="steps">
                <template v-for="step in steps_workflow">
                  <div
                    class="step-wrapper"
                    v-if="!(step.ordre === 5 && is_actif)"
                    :key="step.ordre"
                    :class="{
                      validated: !!demande[step.date_prop],
                      active: demande_step.ordre === step.ordre,
                    }"
                  >
                    <div class="step-block">
                      <span class="step-icon"> <i :class="step.icon"></i></span>
                      <span class="step-content">
                        <span>{{ step.libelle }} </span>
                        <span
                          v-if="
                            demande[step.date_prop] &&
                            (demande_step.ordre > step.ordre ||
                              (step.ordre === 1 && is_statut(statuts.NOUVEAU)) ||
                              (step.ordre === 5 && is_statut(statuts.INACTIF)))
                          "
                        >
                          Fait le
                          {{ $moment(demande[step.date_prop]).format("DD/MM/YYYY") }}
                        </span>
                        <span v-else-if="demande_step.ordre === 4 && demande[step.date_prop]"
                          >Signé le
                          {{ $moment(demande[step.date_prop]).format("DD/MM/YYYY") }}</span
                        >
                        <span v-else-if="demande_step.ordre === step.ordre">En cours</span>
                      </span>
                    </div>
                    <span className="step-line"></span>
                  </div>
                </template>
              </div>
            </div>
            <div v-if="is_statut(statuts.ACTIF) || is_statut(statuts.SIGNATURE)">
              <a
                :href="support_presentation_path"
                download
                class="btn btn-primary mb-1"
                target="_blank"
              >
                <span>Générer le support de présentation aux administrés</span>
              </a>
            </div>
            <div>
              <!-- Générer la convention -->
              <div v-if="is_statut(statuts.PRESENTATION) || is_statut(statuts.SIGNATURE)">
                <button class="btn btn-primary" @click="onGetConvention">
                  <span>Générer la convention</span><i class="fa-solid fa-arrow-right-long"></i>
                </button>
                <a
                  ref="resultatConvention"
                  hidden
                  :href="urlOutput"
                  :download="outputFile"
                  target="_blank"
                  >Télécharger le document</a
                >
              </div>

              <!-- Charger et modifier la convention signée -->
              <div
                class="flex-btn-group mb-14"
                v-if="is_statut(statuts.SIGNATURE) || is_statut(statuts.A_VALIDER)"
              >
                <a
                  v-if="!edit_convention && convention_path"
                  :href="convention_path"
                  download
                  class="btn btn-link"
                  target="_blank"
                >
                  <i class="fa-solid fa-download"></i> <span>Télécharger la convention</span>
                </a>
                <div class="d-flex flex-column" v-if="edit_convention || !convention_path">
                  <label>Charger la convention signée</label>
                  <div class="d-flex align-items-center">
                    <field-dropzone-file
                      v-model="convention_file"
                      :rules="validations.convention"
                      ref="convention_file"
                      :acceptFilesType="[
                        'application/pdf',
                        'application/msword',
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                      ]"
                    />
                    <button
                      class="btn btn-secondary btn-medium"
                      @click="edit_convention = false"
                      v-if="edit_convention"
                    >
                      Annuler
                    </button>
                    <button class="btn btn-primary btn-medium" @click="onUpdateConvention">
                      <span>Valider</span><i class="fa-solid fa-arrow-right-long"></i>
                    </button>
                  </div>
                </div>
                <button
                  v-if="!edit_convention && convention_path"
                  @click="edit_convention = true"
                  class="btn btn-medium btn-primary"
                >
                  Modifier
                </button>
              </div>

              <!-- Voir la convention -->
              <div v-if="is_statut(statuts.ACTIF)">
                <div class="btn-group">
                  <a class="btn btn-primary" :href="demande.convention_lien" target="_blank">
                    <span>Voir la convention</span><i class="fa-solid fa-arrow-right-long"></i>
                  </a>
                </div>
                <div class="btn-group ml-5">
                  <button
                    v-if="!edit_convention_lien && isUserLogged"
                    @click="onEditConventionLien"
                    class="btn btn-medium btn-primary"
                  >
                    Modifier le lien
                  </button>
                </div>
                  
              </div>
            </div>
          </div>
          <div class="col col-xs-6 col-sm-12 col-md-6">
            <!-- informations de la demande -->
            <div class="infos">
              <h2>
                Informations
                <a
                  href="https://groupama.service-now.com/services_portal_groupama?id=gsp_mes_commande"
                  target="_blank"
                  >{{ demande.snow_reference }}</a
                >
              </h2>
              <demande-infos :demande="demande" />
            </div>
          </div>
          <div class="col-8">
            <!-- Valider le lien de la convention -->
            <div class="d-flex mt-3" v-if="(is_statut(statuts.A_VALIDER) || edit_convention_lien) && isUserLogged">
              <custom-input-type
                type="text"
                v-model="convention_lien"
                class="inline full-height flex-grow-1 mb-0"
                :rules="validations.convention_lien"
                ref="convention_lien"
              />
              <button class="btn btn-primary ml-14" @click="edit_convention_lien ? submitNewConventionLien() : onValiderConvention()">
                <span>Valider</span><i class="fa-solid fa-arrow-right-long"></i>
              </button>

              <button v-if="edit_convention_lien" class="btn btn-primary ml-14" @click="edit_convention_lien = false">
                <span>Annuler</span><i class="fa-solid"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <!-- Abandon -->
            <div class="flexbox justifyCenter mt-40" v-if="demande_step.ordre > 1 && is_actif">
              <button @click="to_abandon = true" class="btn btn-secondary">
                <span>La commune n'est plus interessée</span
                ><i class="fa-regular fa-arrow-right"></i>
              </button>
              <custom-modal v-if="to_abandon" @close="to_abandon = false" @confirm="onAbandonner">
                <template v-slot:modalTitle>Abandon</template>
                <template v-slot:modalBody>
                  <custom-basic-select
                    label="Motif d'abandon : "
                    :options="optionsMotif"
                    v-model="motif_abandon"
                    ref="motif_abandon"
                    :rules="validations.motif_abandon"
                  />
                  <custom-input-type v-if="motif_abandon === '2'" v-model="autre_motif" />
                </template>
                <template v-slot:closeBtn>Annuler</template>
                <template v-slot:confirmationBtn
                  >Suivant <i class="fa-solid fa-arrow-right-long"></i
                ></template>
              </custom-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-else-if="demande && !is_actif" class="page-content">
      <div class="infos">
        <h2>
          Informations
          <a :href="demande.snow_lien" target="_blank">{{ demande.snow_reference }}</a>
        </h2>
        <demande-infos :demande="demande" />
      </div>
    </div> -->
    <div v-else class="no-data">La demande n'existe pas ou elle est inactive</div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FieldDropzoneFile from "../../../components/customize/FieldDropzoneFile.vue";
import DemandeInfos from "./DemandeInfos.vue";
import { WORKFLOW_STEPS } from "../../../../config/Properties";
import {
  DEMANDE_STATUT,
  DEMANDE_STATUT_CLE,
} from "../../../../../mutuelle-communale-api/app/configs/Properties";
import CustomInputType from "../../../components/customize/CustomInputType.vue";
import CustomModal from "../../../components/customize/CustomModal.vue";
import CustomBasicSelect from "../../../components/customize/CustomBasicSelect.vue";
import { checkValidationRefs } from "../../../utils/validate";
export default {
  components: { DemandeInfos, FieldDropzoneFile, CustomInputType, CustomModal, CustomBasicSelect },
  name: "DemandeWorkflow",
  data() {
    return {
      demande: null,
      convention_file: null,
      convention_lien: null,
      edit_convention: false,
      edit_convention_lien: false,
      to_abandon: false,
      statuts: DEMANDE_STATUT_CLE,
      motif_abandon: null,
      autre_motif: null,
      outputFile: "convention.docx",
      urlOutput: "#",
      validations: {
        convention: {
          required: "Merci de charger la convention",
          accept: {
            message: "Les formats autorisés sont que de pdf",
            extensions: ["pdf", "docx"],
          },
        },
        convention_lien: { required: "Merci de renseigner le lien de la convention" },
        motif_abandon: { required: "Merci de selectionner le motif" },
      },
    };
  },
  computed: {
    ...mapGetters(["isUserLogged"]),
    id_demande() {
      return this.$route.params.id;
    },
    steps_workflow() {
      return WORKFLOW_STEPS;
    },
    demande_step() {
      return WORKFLOW_STEPS[DEMANDE_STATUT[this.demande.statut].step_workflow];
    },
    convention_path() {
      return (
        this.demande.convention_file_path &&
        `${process.env.VUE_APP_API_URL}/uploads/${this.demande.convention_file_path}`
      );
    },
    support_presentation_path() {
      return `${process.env.VUE_APP_API_URL}/uploads/SupportPresentationAdministres.pptx`;
    },
    is_actif() {
      return this.demande.statut !== DEMANDE_STATUT_CLE.INACTIF;
    },
    optionsMotif() {
      return {
        0: "Choix pour un autre concurrent",
        1: "Abandon du projet",
        2: "Autre",
      };
    },
  },
  methods: {
    ...mapActions([
      "getDemande",
      "setLoading",
      "setError",
      "updateDemande",
      "getConvention",
      "uploadConvention",
    ]),
    onGetConvention() {
      return this.setLoading(true)
        .then(() => this.getConvention(this.demande))
        .then(response => {
          return (this.urlOutput = window.URL.createObjectURL(new Blob([response.data])));
        })
        .then(() => this.$refs.resultatConvention.click())
        .then(() =>
          this.updateDemande({
            id_demande: this.demande.id_demande,
            statut: DEMANDE_STATUT_CLE.SIGNATURE,
            date_presentation: this.demande.date_rdv,
          })
        )
        .then(() => this.$router.push({ name: "Departements" }))
        .catch(e => {
          alert(e);
          return this.setError(e);
        })
        .then(() => this.setLoading(false));
    },
    onAbandonner() {
      let motif =
        this.motif_abandon === "2" && this.autre_motif
          ? `Autre (${this.autre_motif})`
          : this.optionsMotif[this.motif_abandon];
      return this.setLoading(true)
        .then(() => {
          this.to_abandon = false;
          return this.updateDemande({
            id_demande: this.demande.id_demande,
            statut: DEMANDE_STATUT_CLE.INACTIF,
            motif_abandon: motif,
            date_abandon: this.$moment().toJSON(),
          });
        })
        .then(() => this.$router.push({ name: "Departements" }))
        .catch(e => this.setError(e))
        .then(() => this.setLoading(false));
    },
    onEditConventionLien() {
      this.edit_convention_lien = true;
      this.convention_lien = this.demande.convention_lien;
    },
    onUpdateConvention() {
      if (!checkValidationRefs([this.$refs.convention_file])) return window.scroll(0, 0);
      this.edit_convention = false;
      return this.setLoading(true)
        .then(() => {
          this.uploadConvention({
            id_demande: this.demande.id_demande,
            convention_file: this.convention_file
          });
        }
        )
        .then(() => this.$router.push({ name: "Departements" }))
        .catch(e => this.setError(e))
        .then(() => this.setLoading(false));
    },
    onValiderConvention() {
      if (!checkValidationRefs([this.$refs.convention_lien])) return window.scroll(0, 0);
      return this.setLoading(true)
        .then(() =>
          this.updateDemande({
            id_demande: this.demande.id_demande,
            convention_lien: this.convention_lien,
            statut: DEMANDE_STATUT_CLE.ACTIF,
            date_convention: this.$moment().toJSON(),
          })
        )
        .then(() => this.$router.push({ name: "Departements" }))
        .catch(e => this.setError(e))
        .then(() => this.setLoading(false));
    },
    submitNewConventionLien() {
      if (!checkValidationRefs([this.$refs.convention_lien])) return window.scroll(0, 0);
      return this.setLoading(true)
        .then(() =>
          this.updateDemande({
            id_demande: this.demande.id_demande,
            convention_lien: this.convention_lien,
            without_notification: true
          })
        ) // On met à jour la demande via l'API
        .then(() => this.getDemande(this.id_demande))
        .then(demande => (this.demande = demande))
        .catch(e => this.setError(e))
        .then(() => {
          this.edit_convention_lien = false;
          this.setLoading(false);
        });
    },
    is_statut(s) {
      return this.demande.statut === DEMANDE_STATUT_CLE[s];
    },
  },
  mounted() {
    return this.setLoading(true)
      .then(() => this.getDemande(this.id_demande))
      .then(demande => (this.demande = demande))
      .catch(e => this.setError(e))
      .then(() => this.setLoading(false));
  },
  watch: {
    motif_abandon: {
      handler(val) {
        if (val !== "2") this.autre_motif = null;
      },
    },
  },
};
</script>
