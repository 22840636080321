<template>
  <div id="wrapper">
    <app-header v-if="showAppHeader" />
    <loading v-if="loading" />
    <error-api v-if="hasError" />
    <router-view />
    <app-footer v-if="showAppFooter" />
  </div>
</template>
<script>
import AppHeader from "./components/layouts/Header.vue";
import AppFooter from "./components/layouts/Footer.vue";
import { mapGetters } from "vuex";
import Loading from "./components/layouts/Loading.vue";
import ErrorApi from "./components/layouts/ErrorApi.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
    Loading,
    ErrorApi,
  },
  computed: {
    ...mapGetters(["showAppHeader", "showAppFooter", "loading", "hasError"]),
  },
};
</script>
