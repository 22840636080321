<template>
  <div :class="['field', validated && errors ? 'field-error' : '']">
    <div class="field-block">
      <div v-if="validated && errors" class="error-block">
        <span class="error-message">{{ errors }}</span>
      </div>
      <div class="buttons-radio">
        <div
          :class="modelValue === btn.value ? 'btn-radio  btn-radio-active' : 'btn-radio'"
          @click="onChange(btn.value)"
          v-for="(btn, index) in buttons"
          :key="index"
        >
          {{ btn.label }}
        </div>
      </div>
    </div>
    <label class="form-label" v-if="label"> {{ label }}</label>
  </div>
</template>

<script>
import { getError } from "../../utils/validate";

export default {
  emits: ["update:modelValue"],
  props: {
    clearValue: {},
    label: {
      type: String,
      default: null,
    },
    buttons: {
      type: Array,
      default: null,
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: null,
    },
    rules: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      validated: false,
    };
  },
  computed: {
    errors() {
      return getError(this.modelValue, { ...this.rules }, {});
    },
  },
  methods: {
    onChange(value) {
      if (value === this.modelValue) {
        if (this.clearValue) {
          this.clearValue();
        }
        this.$emit("update:modelValue", null);
      } else {
        this.$emit("update:modelValue", value);
      }
    },
    validate() {
      this.validated = true;
    },
  },
};
</script>
<style scoped></style>
