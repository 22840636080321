<template>
  <tr class="add-row">
    <td>
      <custom-input-type type="text" ref="user.nom" v-model="user.nom" :rules="validations.nom" />
    </td>
    <td>
      <custom-input-type
        type="text"
        ref="user.prenom"
        v-model="user.prenom"
        :rules="validations.prenom"
      />
    </td>
    <td>
      <custom-input-type
        type="email"
        ref="user.email"
        v-model="user.email"
        :rules="validations.email"
      />
    </td>
    <td>
      <custom-select
        :disabled="editMode"
        ref="user.role"
        v-model="user.role"
        :rules="validations.role"
        :options="rolesOptions"
      />
    </td>
    <td>
      <button class="btn" @click="$emit('annuller')">Annuler</button>
      <button class="btn btn-primary" @click="onSubmit">Valider</button>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomInputType from "../../../components/customize/CustomInputType.vue";
import CustomSelect from "../../../components/customize/CustomBasicSelect.vue";
import Loading from "../../../components/layouts/Loading.vue";
import { checkValidationRefs } from "../../../utils/validate";
import { ACCOUNTS_ROLES } from "../../../../../mutuelle-communale-api/app/configs/Properties";
export default {
  components: { CustomInputType, Loading, CustomSelect },
  name: "FormUser",
  emits: ["annuller"],
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    userModel: {
      type: Object,
      default() {
        return {
          nom: null,
          prenom: null,
          email: null,
          role: null,
        };
      },
    },
  },
  data() {
    return {
      user: this.userModel,
      formValid: true,
    };
  },
  computed: {
    ...mapGetters(["loading"]),
    validations() {
      return {
        nom: {
          required: "Merci de renseigner un nom",
        },
        prenom: {
          required: "Merci de renseigner un prénom",
        },
        role: {
          required: "Merci de renseigner un rôle",
        },
        email: {
          required: "Merci de renseigner un mail",
          email: "Merci de renseigner un mail valide",
        },
      };
    },
    rolesOptions() {
      return ACCOUNTS_ROLES;
    },
  },
  methods: {
    ...mapActions(["sendUserBD", "setLoading", "addUserDB"]),
    onSubmit() {
      this.checkValidations();
      if (!this.formValid) {
        return window.scroll(0, 0);
      }

      return this.setLoading(true)
        .then(() => this.addUserDB(this.user))
        .then(() => {
          this.$parent.isCreate = false;
          this.$parent.isEdit = false;
          this.$parent.idUserToEdit = null;
        })
        .catch(e => {
          alert(e.response?.data?.message);
        })
        .then(() => this.setLoading(false));
    },
    checkValidations() {
      this.formValid = checkValidationRefs(this.$refs);
    },
  },
};
</script>

<style></style>
