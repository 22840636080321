<template>
  <loading v-if="loading" />
  <div class="box animated">
    <div class="box-header">
      <div class="logo"><i class="fa-light fa-sitemap"></i><span>Mutuelles Communales</span></div>
    </div>
    <div class="box-content">
      <form @submit.prevent="checkUserLogin">
        <div class="row">
          <custom-input-type
            class="col-12"
            v-model="user.email"
            label="Email"
            type="email"
            :rules="validations.user.email"
            ref="user.email"
          />
          <custom-input-type
            class="col-12"
            v-model="user.password"
            label="Mot de passe"
            type="password"
            :rules="validations.user.password"
            ref="user.pass"
          />
          <div class="col-12 d-grid">
            <button type="submit" class="btn btn-login btn-primary">Se connecter</button>
          </div>
          <div class="col-12">
            <div class="flex-btn-group center">
              <button type="button" class="btn-password" @click="showModalResetPass = true">
                Mot de passe oublié ?
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <custom-modal
    v-if="showModalResetPass"
    @close="showModalResetPass = false"
    :hasHeader="true"
    :hasFooter="false"
  >
    <template v-slot:modalTitle>Réinitialisation de votre mot de passe</template>
    <template v-slot:modalBody>
      <reset-password :userEmail="user.email" />
    </template>
  </custom-modal>
  <custom-modal v-if="authError" @close="authError = false" :hasHeader="true" :hasFooter="false">
    <template v-slot:modalBody>
      <div v-if="authError">
        <span>Erreur d'authentification</span><br />
        <span>Mot de passe ou email est incorrect</span>
      </div>
    </template>
  </custom-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomInputType from "../../components/customize/CustomInputType.vue";
import CustomModal from "../../components/customize/CustomModal.vue";
import Loading from "../../components/layouts/Loading.vue";
import ResetPassword from "./ResetPassword.vue";
import { checkValidationRefs } from "../../utils/validate";

export default {
  components: {
    CustomInputType,
    Loading,
    CustomModal,
    ResetPassword,
  },
  data() {
    return {
      authError: false,
      user: {
        email: null,
        password: null,
        remember: false,
      },
      validations: {
        user: {
          email: {
            required: { message: "Merci de renseigner un email" },
            email: { message: "Merci de renseigner un email valide" },
          },
          password: {
            required: { message: "Merci de renseigner un mot de passe" },
          },
        },
      },
      showModalResetPass: false,
      formValid: true,
    };
  },
  computed: {
    ...mapGetters(["loading"]),
    currentUser() {
      return this.user;
    },
  },
  methods: {
    ...mapActions(["setLoading", "setAppFooter", "setAppHeader", "checkLogin"]),
    checkUserLogin() {
      this.authError = false;
      this.checkValidations();
      if (!this.formValid) {
        return window.scroll(0, 0);
      }

      return this.setLoading(true)
        .then(() => this.checkLogin(this.user))
        .then(isConnect => {
          if (!isConnect) {
            this.authError = true;
            return isConnect;
          }
          return this.$router.push({ name: "Departements" });
        })
        .then(() => this.setLoading(false));
    },
    checkValidations() {
      this.formValid = checkValidationRefs(this.$refs);
    },
  },
  created() {
    return this.setAppHeader(false).then(() => this.setAppFooter(false));
  },
};
</script>
