<template>
  <loading v-if="loading" />

  <div v-if="!mailSent">
    <custom-input-type
      type="email"
      v-model="email"
      placeholder="test@mail.com"
      label="Adresse email *"
      :rules="validations.email"
      ref="email"
    />
    <div class="modal-footer">
      <button class="btn btn-primary" @click="submit">Envoyer</button>
    </div>
    <span class="error-message">
      {{ global.errorMsg }}
    </span>
  </div>
  <div v-if="mailSent">
    <span> Une email a été envoyé à {{ email }} pour réinitialiser votre de mot passe </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomInputType from "../../components/customize/CustomInputType.vue";

import Loading from "../../components/layouts/Loading.vue";
import { checkValidationRefs } from "../../utils/validate";

export default {
  components: { CustomInputType, Loading },
  props: {
    userEmail: String,
  },
  data() {
    return {
      email: this.userEmail,
      validations: {
        notExist: "L'utilisateur n'existe pas",
        errorSendMail: "Impossible d'envoyer l'email",
        email: {
          required: "Merci de renseigner un mail",
          email: "Merci de renseigner un mail valide",
        },
      },
      global: {
        errorMsg: null,
      },
      formValid: true,
      mailSent: false,
    };
  },
  computed: { ...mapGetters(["loading"]) },
  methods: {
    ...mapActions(["resetPassword", "isUserExist", "setLoading"]),
    submit() {
      this.checkValidations();
      if (!this.formValid) {
        return window.scroll(0, 0);
      }
      return this.setLoading(true)
        .then(() => this.isUserExist(this.email))
        .then(isExist => {
          if (!isExist) {
            this.global.errorMsg = this.validations.notExist;
            return;
          }
          return this.resetPassword(this.email).then(isSendEmail => {
            if (isSendEmail) {
              this.mailSent = true;
            } else {
              this.global.errorMsg = this.validations.errorSendMail;
            }
          });
        })

        .then(() => this.setLoading(false));
    },
    checkValidations() {
      this.formValid = checkValidationRefs(this.$refs);
    },
  },
  watch: {
    email(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.global.errorMsg = null;
      }
    },
  },
};
</script>

<style></style>
