<template>
  <header class="app-header">
    <span>Ici va le Header</span>
  </header>
</template>
<script>
export default {
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped></style>
