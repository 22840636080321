// Auth Components
import Auth from "./IndexAuth.vue";
import Login from "./Login.vue";
import NewPassword from "./NewPassword.vue";
export const routes = [
  {
    path: "/auth",
    name: "Auth",
    component: Auth,
    children: [
      {
        path: "login",
        name: "Auth.Login",
        component: Login,
      },
      {
        path: "newpass/:reset_token",
        name: "Auth.NewPassword",
        component: NewPassword,
      },
    ],
  },
];
