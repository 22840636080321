import { mapGetters } from "vuex";

export const mixinAction = {
  computed: {
    ...mapGetters([
      "isAdmin",
      "isDA",
      "loading",
      "currentUser",
      "usersListe",
      "getUserFromListe",
      "userRole",
    ]),
    hasAction() {
      return this.isAdmin || this.isDA;
    },
  },
  methods: {},
};
